// extracted by mini-css-extract-plugin
export var about = "home-module--about--u4ecZ";
export var aboutMe = "home-module--aboutMe--mvcyl";
export var feature = "home-module--feature--EH5OD";
export var features = "home-module--features--Ub0tB";
export var heroSection = "home-module--heroSection--2D5+t";
export var m = "home-module--m--cYbhM";
export var nomb = "home-module--nomb--YWktO";
export var nomt = "home-module--nomt--uf4AL";
export var photo = "home-module--photo--Vh9AO";
export var pogledaj = "home-module--pogledaj--zuQfx";
export var sub = "home-module--sub--mWU3u";
export var text = "home-module--text--JQSsO";
export var textbelow = "home-module--textbelow--t9D1N";