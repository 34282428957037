import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/Layout";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import me from '../images/me.jpg'
import * as styles from "../styles/home.module.css";

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <div className={styles.heroSection}>
          <h1>Nutricionista Sonja Nikačević</h1>
          <h3>
            Ukoliko živite zdravo i vodite računa o svojoj ishrani, bićete
            sigurno srećniji i zadovoljniji i uživaćete u svakom trenutku.
          </h3>
          <Link to="/pitajte-nutricionistu" className="headerButton">
            PITAJTE NUTRICIONISTU
          </Link>
        </div>
        <div className={styles.features}>
          <div className={styles.feature}>
            <ContentPasteSearchIcon />
            <h3>Online anketa</h3>
            <p>
              Ako vam je potrebna stručna pomoć, a niste u mogućnosti da zbog
              daljine ili obaveza dođete na pregled kod nutricioniste i dobijete
              dijetu direktno, onda je on-line dijeta pravo rešenje za vas.
            </p>
            <Link className={styles.pogledaj} to="/anketa">
              Pogledaj
            </Link>
          </div>
          <div className={`${styles.feature} ${styles.m}`}>
            <QuestionAnswerIcon />
            <h3>Pitajte nutricionistu</h3>
            <p>
              Ukoliko vam je potreban savet ili imate neko pitanje u vezi sa
              ishranom i zdravljem, a niste u mogućnosti da dođete, ovde ga
              možete postaviti. Nutricionista će vam odgovoriti u najkraćem
              mogućem roku.
            </p>
            <Link className={styles.pogledaj} to="/pitajte-nutricionistu">
              Pogledaj
            </Link>
          </div>
          <div className={styles.feature}>
            <MonitorWeightIcon />
            <h3>BMI Kalkulator</h3>
            <p>
              Indeks telesne mase (BMI) nam pokazuje odnos između težine i
              visine našeg tela. U zavisnosti od rezultata merenja i u odnosu na
              vaše godine i pol, naš BMI kalkulator vam daje informaciju o tome
              da li imate normalnu težinu ili ne.
            </p>
            <Link className={styles.pogledaj} to="/kalkulator">
              Pogledaj
            </Link>
          </div>
        </div>
        <div className={styles.aboutMe}>
          <span className={styles.sub}>O nutricionisti</span>
          <div className={styles.about}>
            <div className={styles.text}>
              <p className={styles.nomt}>
                Ja sam Sonja Nikačević(rođ.Papić) dijetetičar - nutricionista.
                Radim kao dijetetičar - nutricionista od novembra 2006. godine.
                Najpre kao nutricionista konsultant Studentskog centra Čačak, a
                zatim i u Atomskoj banji Gornja Trepča. Radila sam u
                Zdravstvenom centru Čačak na poslovima dijetetičara -
                nutricioniste sve do 2017. godine kada sam osnovala
                Nutricionistički studio u kom aktivno i danas radim.{" "}
              </p>
              <p className={styles.nomb}>
                Nutricionista konsultant sam u predškolskoj ustanovi Cvetić u
                Kniću, kao i u internističkoj ordinaciji Hipokrat Čačak. Iza
                mene su brojne održane tribine i predavanja,kako u predškolskim
                ustanovama tako i u školama zapadne Srbije, u školama sporta,
                udruženju obolelih od MS itd. Radim individualne jelovnike za
                sve uzraste, sportiste, trudnice, dojilje, redukcione i
                medicinske dijete (insulinska rezistencija, hipertenzija,
                psorijaza, gastritis, anemija, hašimoto, helikoibakterija,
                kandida, kod dijabetesa, artritisa i dr.), a izrađujem i
                jelovnike za one koji nemaju zdravstveni problem već žele da se
                zdravo hrane.
              </p>{" "}
              <p>
                Koristi od pravine ishrane su mnogbrojne: jačanje imuniteta,
                zaštita organizma od bolesti, manji procenat masti, odgovarajuća
                telesna težina, smanjen rizik nastanka kardiovaskularnih
                bolesti, bolji san i lakše buđenje ujutru, psihička stabilnost,
                fizička spremnost, bolja pokretljivost, smanjeni otoci i bolovi
                u zglobovima, i niz drugih prednosti. Svaka osoba sa viškom
                kilograma treba da redukuje telesnu težinu, da je adekvatno
                održava, da postavi cilj, da promeni navike i nauči zdravije da
                živi. Ako sve to budete uspeli bićete pre svega zdraviji, a onda
                i zadovoljniji. Ključ zdravlja je u Vama, a ja sam tu da Vam
                pomognem da dodjete do tog cilja.
              </p>
            </div>
            <div className={styles.photo}>
              <img
                alt="sonja nikacevic"
                src={me}
              />
              <em style={{color: 'gray'}}>Sonja Nikačević, nutricionista</em>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <title>Sonja Nikacevic | Nutricionista</title>
    <meta name="description" content="Sonja Nikacevic nutricionista Cacak" />
    <meta name="keywords" content="Sonja Nikacevic, nutrimeal, nutricionista, cacak"></meta>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
  </>
)
